module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Get GED Test Online From Home in 2024 | Pay After You Pass","short_name":"GED Test Online","start_url":"/","background_color":"white","theme_color":"#101C45","display":"standalone","lang":"en","icon":"src/images/icon.png","icons":[{"src":"src/images/icon_48x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"src/images/icon_72x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"src/images/icon_96x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"src/images/icon_144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"src/images/icon_192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/images/icon_256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"src/images/icon_384x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"src/images/icon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"description":"We Help You To Pass the Online GED Test on the first attempt without any stress. If Fail free Exam Retake & Extended Support definitely Pay After Pass","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
